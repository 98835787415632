import "bootstrap/dist/css/bootstrap.css";
import "modern-normalize/modern-normalize.css";
import 'intl-tel-input/build/css/intlTelInput.min.css';

import "./utils";
import "./swiper";
import "./modal";
import "./menu"
import "./topScroll"
import "./intlTelInput"



import "../css/main.scss";
