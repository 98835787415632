import {showBackdrop, hideBackdrop} from "./utils";
import refs from "./refs";

const {menuBackdrop, menuButton, appBackdrop, appButtons, hideButton, videoButton, hideVideoButton, videoBackdrop , videoThumb ,tyBackdrop} =
    refs;

const videoSrc = videoThumb.attr('src');

const handleAppButtonClick = () => {
    if (!$(menuBackdrop).hasClass("is-hidden")) {
        hideBackdrop(menuBackdrop);

    }

    showBackdrop(appBackdrop);
};

const handleVideoButtonClick = () => {
    showBackdrop(videoBackdrop);

    if(videoThumb.attr('src') === ''){
        videoThumb.attr('src',videoSrc)
    }
};

document.addEventListener( 'wpcf7mailsent', function( event ) {
    // Знімаємо клас "is-hidden"
    $('#tyForm').removeClass('is-hidden');
}, false );


// При кліці на бекдроп
$('#tyForm').on('click', function(e) {
    if (e.target === this) {
        $(this).addClass('is-hidden'); // Додаємо клас "is-hidden", щоб приховати бекдроп
    }
});


videoButton.on("click", handleVideoButtonClick);

appButtons.on("click", handleAppButtonClick);
hideButton.on("click", () => hideBackdrop(appBackdrop));
hideVideoButton.on("click", () => hideBackdrop(videoBackdrop));


