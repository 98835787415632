const refs = {
  bodyEl: $("body"),
  menuButton: $(".menu-button"),
  menuBackdrop: $("#menu"),
  menuNavLinks: $("#menu .nav-list a"),

  appButtons: $(".app-button-js"),
  hideButton: $(".hide-app-button"),
  appBackdrop: $("#appointment"),
  appHideButton: $(".appointment-button"),

  videoButton: $(".video-wrapper__content"),
  hideVideoButton: $(".hide-video-button"),
  videoBackdrop: $("#videoBackdrop"),
  videoThumb: $("iframe"),

  tyBackdrop: $("#tyForm")

};

export default refs;
